.l-page__content {
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
}

.l-page__options {
  display: flex;
  flex-direction: column;
}
.l-page__form {
  width: 100%;
}

.l-page__form_footer {
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
}