.l-landing__content {
  display: flex;
  max-width: 450px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
}

.l-landing__options {
  display: flex;
  flex-direction: column;
}
